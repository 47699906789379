<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        permission="estrutura_grupos"
        newPath="/companies-exporta-dados/new"
        editPath="/companies-exporta-dados/"
        :expander="false"
        subtitle="Companies exporta dados"
        tooltip="Cadastro de Companies exporta dados"
        nomeTelaDoManual="companies-exporta-dados-list"
    >
        <template #columns>
            <Column field="id" header="Cod." :sortable="true" sortField="id"></Column>
            <Column field="nome" header="Nome" :sortable="true" sortField="nome"></Column>
            <Column field="chaveAcesso" header="Chave de Acesso" :sortable="true" sortField="chaveAcesso"></Column>
            <Column field="createdAt" header="Criado" :sortable="true" sortField="created_at">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                </template>
            </Column>
            <Column field="updatedAt" header="Alterado" :sortable="true" sortField="updated_at">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.updatedAt) }}
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
</template>
<script>
import BaseService from '../../services/BaseService';
import { getCurrentCompany } from '../../services/store';
export default {
    data() {
        return {
            service: null
        };
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        }
    },
    watch: {
        tenant() {
            this.load();
        }
    },
    created() {
        this.service = new BaseService('/companies-exporta-dados');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        }
    }
};
</script>
